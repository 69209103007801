export function dateToMinute(date: any) {
  const s = date instanceof Date ? date.toISOString() : (date as string)
  return s.substring(0, 16)
}

export function matchOr(str: string, regex: RegExp, or: string = '') {
  const match = str.match(regex)
  if (match) {
    return match[0]
  }
  return or
}

export function mapToJson<T>(map: Map<string, T>): Record<string, T> {
  const jsonObject: Record<string, T> = {}

  map.forEach((value, key) => {
    jsonObject[key] = value
  })

  return jsonObject
}

export function toCamelCase(input: string): string {
  return input
    .split('.')
    .map((word, index) => {
      if (index === 0) {
        // Keep the first word as is
        return word
      }
      // Capitalize the first letter of each subsequent word
      return word.charAt(0).toUpperCase() + word.slice(1)
    })
    .join('')
}
